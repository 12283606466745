html,
body,
#root,
#root {
    background-color: #fafbff;
    font-family: Helvetica !important;
    width: 100%;
    height: auto;
    margin: 0;
    overflow-x: hidden;
    scroll-behavior: smooth;
}
